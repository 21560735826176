import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {PRIMARY_COLOR, PRIMARY_COLOR_1, TEXT_COLOR} from '../constants';
import {getPixelsOrString, isNumberOrString} from '../helpers';

const Paragraph = function ({children, textAlign = null, maxWidth = null}) {
	return (
		<StyledParagraph $textAlign={textAlign} $maxWidth={maxWidth}>
			{children}
		</StyledParagraph>
	);
};

Paragraph.propTypes = {
	children: PropTypes.node,
	textAlign: PropTypes.oneOf(['left', 'center', 'right', 'inherit']),
	maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const StyledParagraph = styled.p`
	display: block;
	width: 100%;
	max-width: ${({$maxWidth}) => (isNumberOrString($maxWidth) ? getPixelsOrString($maxWidth) : 'unset')};
	line-height: 1.8;
	//margin: 0.9rem auto;
	margin: 1.5rem auto;
	font-size: 1rem;
	color: ${TEXT_COLOR};
	text-align: ${({$textAlign}) => $textAlign || 'left'};

	a {
		border-bottom: 2px solid ${PRIMARY_COLOR};
		color: ${TEXT_COLOR};
		transition: border-bottom-color 100ms ease;

		&:hover {
			border-bottom-color: ${PRIMARY_COLOR_1};
		}
	}

	code {
		color: ${PRIMARY_COLOR};
		font-size: 1.06em;
	}
`;

export default Paragraph;
