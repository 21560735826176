import React from 'react';
import {Link} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import SignUpCTA from '../../components/SignUpCTA';
import Nav from '../../components/Nav';
import AboutPackageContainer from '../../components/AboutPackageContainer';
import Paragraph from '../../ui/Paragraph';
import EnvData from '../../components/EnvData';
import {aboutStandardRoute} from '../../routes';
import useEnvContext from '../../hooks/useEnvContext';
import PremiumPricePackage from '../../components/PricePackages/PremiumPricePackage';

export default function PremiumPage() {
	return (
		<EnvData>
			<ThePage />
		</EnvData>
	);
}

const ThePage = function () {
	const {signUpRoute} = useEnvContext();

	return (
		<Layout title="PREMIUM paket usluga" description="Saznajte detalje o PREMIUM paketu usluga, što točno nudi i koje su cijene">
			<Nav background="#931247" />

			<AboutPackageContainer color2="#cd5077" color1="#931247">
				<aside>
					<PremiumPricePackage />
				</aside>
				<main>
					<h1>PREMIUM paket</h1>
					<h2 className="mt-2">Sve is STANDARD paketa</h2>
					<Paragraph>Ovaj paket sadržava sve mogućnosti što i STANDARD paket, plus slijedeće:</Paragraph>
					<h2>Mogućnost postavljanja slike uz svaku stavku cjenika</h2>
					<Paragraph>
						Možete dodati sliku za svaku stavku vašeg cjenika. Sve slike koje ubacite u cjenik će biti optimizirane u više različitih
						dimenzija kako bi odlično izgledale na malim ekranima. Preporučujemo da ubacite što više slika dobre rezolucije, jer slika će
						najbolje prodati Vaš proizvod. Čak i da imate 5000 stavki u svom cjeniku, sustav će ih sve prihvatiti.
					</Paragraph>
					<div className="img-max-500">
						<img src="/images/capricciosa.jpg" alt="Primjer pizza slike" />
					</div>
					<h2 className="mt-2">Strojna pomoć pri prevođenju</h2>
					<Paragraph>
						Sa paketom STANDARD je moguće sve prevesti na sve jezike, a s PREMIUM paketom dobivate i strojnu pomoć pri prevođenju na ostale
						jezike. Hrvatski jezik je glavni jezik, a kada postoji tekst (ili naslov) na hrvatskom, onda je moguće taj tekst na hrvatskom
						strojno prevesti i na sve ostale jezike koje ste označili u postavkama cjenika. Prevođenje na ostale jezike je moguće običnim
						klikom na tipku <em>Prevedi</em>.
					</Paragraph>
					<div className="img-max-500" style={{maxWidth: '590px'}}>
						<img src="/images/prevedi.png" alt="Primjer prevedi" />
					</div>
					<Paragraph>
						<small>
							* ova mogućnost je samo <u>pomoć</u> pri prevođenju - konačni prijevod i dalje trebate dati stručnoj osobi na provjeru
						</small>
					</Paragraph>
					<h2 className="mt-2">Mogućnost dodavanja više QR kȏdova za svaki stol ili lokaciju</h2>
					<Paragraph>
						Za svoj uslužni objekt možete napraviti više QR kodova i strateški ih postaviti kako i gdje god želite. Svi napravljeni QR
						kodovi će otvarati onaj cjenik koji je u tom trenutku postavljen kao aktivan, a naš sustav će bilježiti statistiku o broju
						skeniranja svakog koda kroz vrijeme.
					</Paragraph>
					<Paragraph>
						Ako želite, možete napraviti po jedan QR kȏd za svaki stol pa ćete imati statistiku skeniranja za svaki stol. Ili, ako imate
						terasu i unutarnji prostor, možete napraviti jedan QR kȏd za terasu, a jedan za unutarnji prostor. Ili, ako tiskate promo
						materijale, preporučujemo Vam da svakako napravite jedan poseban QR kȏd za (svaki) promo materijal kako bi dobili statistiku
						korištenja.
					</Paragraph>
					<h2>Mogućnost vremenskog tempiranja akcija i dnevnih, tjednih i mjesečnih menija</h2>
					<Paragraph>
						Svaki blok sadržaja koji ubacite (grupu stavki, slike, tekstove, linkove na dostavne službe,...) možete vremenski ciljati tako
						da se u vašem cjeniku vide na točno određeni datum i vrijeme, ili možete postaviti da se blok vidi periodički, svaki dan u
						određeno vrijeme, samo određene dane u tjednu ili samo neke dane u mjesecu. To Vam omogućava pravljenje raznih akcija ili
						dnevne, tjedne, pa čak i mjesečne menije u restoranima - unaprijed i bez potrebe da se svaki dan prijavljujete u QR-Cjenik kako
						bi uredili ponudu.
					</Paragraph>
					<Paragraph>
						Primjer: ako dostavljate svoje proizvode putem dostavnih službi (pauza.hr, Glovo, Wolt ili drugim), možete napraviti da se
						linkovi do dostavnih službi koje koristite vide samo za vrijeme vašeg radnog vremena.
					</Paragraph>
					<h2>Mogućnost ubacivanja dodatnog sadržaja: slike, video i dostavni servisi</h2>
					<Paragraph>PREMIUM paket omogućuje ubacivanje dodatnih vrsta sadržaja, kao što su:</Paragraph>
					<ul>
						<li>
							slike koje nisu nužno vezane za stavke cjenika - npr. sliku interijera ili eksterijera; ovake slike su također "višejezične",
							na način da za svaki jezik možete postaviti drugu sliku
						</li>
						<li>
							slike koje predstavljaju cijelu grupu stavki - umjesto da samo napišete naslov, npr. <em>Topli napitci</em>, možete ubaciti
							sliku toplih napitaka
						</li>
						<li>
							možete ubaciti YouTube video koji se može pogledati u samom cjeniku, a koji je također "višejezičan", na način da za svaki
							jezik možete postaviti drugi video
						</li>
						<li>
							linkovi do dostavnih službi - ako koristite dostavne servise tipa pauza.hr, Glovo, Wolt ili dobartek.hr, možete dodati link do
							ponude koju imate na tim servisima i ukoliko je link dobro postavljen, klikom na logo od dostavne službe automatski će se
							otvoriti vaša ponuda u toj aplikaciji
							<br />
							<small>* vrijedi samo ako je aplikacija već instalirana na uređaju vašeg gosta</small>
						</li>
					</ul>
					<h2 className="mt-2">Cijena</h2>
					<Paragraph>
						Ako plaćate iz mjeseca u mjesec, cijena ovog paketa je 19,90€, a ako platite za cijelu godinu unaprijed, onda je cijena 199€ što
						je ekvivalent od 16,58€/mj. Sve cijene su izražene s uključenim PDV-om. Plaćanje se uvijek radi za period koji slijedi, tako da
						ugovorna obveza ne postoji.
					</Paragraph>
					<nav className="mt-2">
						<Link to={aboutStandardRoute()}>&larr; STANDARD paket</Link>
						<OutboundLink href={signUpRoute()} className="primary">
							Isprobaj odmah
						</OutboundLink>
					</nav>
				</main>
			</AboutPackageContainer>

			<SignUpCTA mt="5rem" backgroundColor="#931247" buttonColor="#cd5077" />
			<Footer />
		</Layout>
	);
};
