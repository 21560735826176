import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

import useEnvContext from '../hooks/useEnvContext';
import {PRIMARY_COLOR, PRIMARY_COLOR_2, TEXT_MUTED1} from '../constants';

const PricePackage = function ({aboutLinkTo, children, name, priceEUR, priceCENT, color1 = null, color2 = null}) {
	const {appUrl} = useEnvContext();

	return (
		<StyledPricePackage $color1={color1} $color2={color2}>
			<div>
				<h3>{name}</h3>
				<div>
					<div className="price-wrapper">
						<span>
							<span>{priceEUR}</span>
							<span>{priceCENT}€</span>
							{/*<span>€</span>*/}
						</span>
						<span>
							mjesečno
							<br />
							po objektu
						</span>
					</div>
				</div>
			</div>
			<ul>{children}</ul>
			<nav>
				<Link to={aboutLinkTo} activeClassName="active">
					Saznaj više
				</Link>
				<OutboundLink href={appUrl('registracija')}>Isprobaj odmah</OutboundLink>
			</nav>
		</StyledPricePackage>
	);
};

PricePackage.propTypes = {
	aboutLinkTo: PropTypes.string.isRequired,
	children: PropTypes.node,
	name: PropTypes.string.isRequired,
	priceEUR: PropTypes.number.isRequired,
	priceCENT: PropTypes.number.isRequired,
	color1: PropTypes.string,
	color2: PropTypes.string
};

const StyledPricePackage = styled.div`
	display: block;
	margin: 0 0 2rem 0;
	background: #ffffff;
	border-radius: 0.75rem;
	border: 4px solid #ffffff;
	position: relative;
	box-sizing: border-box;
	padding-bottom: 3rem;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

	> div {
		background: ${({$color1}) => $color1 || PRIMARY_COLOR_2};
		border-top-left-radius: 0.75rem;
		border-top-right-radius: 0.75rem;

		> div {
			background: ${({$color2}) => $color2 || PRIMARY_COLOR};
		}
	}

	.price-wrapper {
		display: block;
		width: 120px;
		height: 120px;
		margin: 0 auto;
		border: 8px solid #ffffff;
		border-radius: 100%;
		background: ${({$color1}) => $color1 || PRIMARY_COLOR_2};
		box-sizing: border-box;
		padding-top: 10px;
		transform: translateY(1.5rem);

		> span {
			display: block;
			text-align: center;
			color: #ffffff;

			&:nth-child(1) {
				transform: translateX(0.2rem);
			}

			&:nth-child(2) {
				// the 2nd row of the price - the label, e.g. "mjesečno/po objektu"
				font-size: 0.75rem;
				font-weight: normal;
				margin-top: -0.15rem;
			}

			> span {
				display: inline-block;
			}

			> span:nth-child(1) {
				// the main price; out of "49.90" -> this span would be the "49"
				font-size: 2rem;
				font-weight: bold;
				margin-left: -10px;
			}

			> span:nth-child(2) {
				// the 2nd price part; out of "49.90" -> this span would be the "90"
				font-size: 1.05rem;
				font-weight: bold;
				transform: translate(0, -55%);
			}

			> span:nth-child(3) {
				// the 2nd price part; out of "49.90kn" -> this span would be the "kn"
				font-size: 1rem;
				font-weight: bold;
				transform: translateX(-0.75rem);
			}
		}
	}

	h3 {
		font-size: 1.2rem;
		font-weight: normal;
		text-align: center;
		color: #ffffff;
		box-sizing: border-box;
		padding: 0.675rem 1rem;
		position: relative;
	}

	> ul {
		display: block;
		margin: 0;
		padding: 2rem 0 0 0;
		box-sizing: border-box;
		list-style: none;

		> li {
			display: block;
			width: 100%;
			margin: 0;
			text-align: center;
			font-size: 0.88rem;
			font-weight: normal;
			box-sizing: border-box;
			padding: 0.75rem 2rem;
			color: ${TEXT_MUTED1};
			line-height: 1.5;
		}
	}

	> nav {
		display: flex;
		align-items: center;
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;

		> a {
			display: block;
			flex: 1;
			text-align: center;
			font-size: 0.85rem;
			font-weight: bold;
			padding: 0.75rem 0.25rem;
			box-sizing: border-box;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:first-child {
				// saznaj više
				background: #efefef;
				border-bottom-left-radius: 0.75rem;
				color: ${TEXT_MUTED1};
			}

			&:last-child {
				// isprobaj besplatno
				background: ${({$color2}) => $color2 || PRIMARY_COLOR};
				color: #ffffff;
				border-bottom-right-radius: 0.75rem;

				@media (hover: hover) and (pointer: fine) {
					&:hover {
						background: ${({$color1}) => $color1 || PRIMARY_COLOR_2};
					}
				}
			}
		}
	}
`;

export default PricePackage;
