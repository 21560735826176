import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {CONTAINER_WIDTH, PRIMARY_COLOR, PRIMARY_COLOR_2, TEXT_MUTED1} from '../constants';

export default function AboutPackageContainer({children = null, color1 = null, color2 = null}) {
	return (
		<Wrapper $color1={color1} $color2={color2}>
			{children}
		</Wrapper>
	);
}

AboutPackageContainer.propTypes = {
	children: PropTypes.node,
	color1: PropTypes.string,
	color2: PropTypes.string
};

const Wrapper = styled.div`
	display: block;
	width: 100%;
	max-width: ${CONTAINER_WIDTH};
	margin: 3rem auto;

	h1 {
		font-size: 2rem;
		font-weight: normal;
		text-align: center;
		margin: 0;
	}

	h2,
	h3 {
		text-align: left;
		line-height: 1.5;
		font-size: 1.2rem;
	}

	> aside {
		display: none;
	}

	> main {
		box-sizing: border-box;
		padding: 1rem;

		> ul,
		> ol {
			margin: 1rem 0;
			padding: 0 1rem;
			box-sizing: border-box;

			> li {
				color: ${TEXT_MUTED1};
				font-size: 0.925rem;
				line-height: 1.5;
				margin: 0.5rem 0;
			}
		}

		> nav {
			display: block;
			align-items: center;

			> a {
				display: block;
				flex: 1;
				text-align: center;
				font-size: 0.8rem;
				font-weight: bold;
				padding: 0.55rem 0.25rem;
				box-sizing: border-box;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				background: #ffffff;
				color: ${TEXT_MUTED1};
				border-radius: 0.75rem;
				box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15);

				&.primary {
					// isprobaj besplatno
					background: ${({$color2}) => $color2 || PRIMARY_COLOR};
					color: #ffffff;

					@media (hover: hover) and (pointer: fine) {
						&:hover {
							background: ${({$color1}) => $color1 || PRIMARY_COLOR_2};
						}
					}
				}

				margin: 1rem;
			}
		}
	}

	@media (min-width: 767px) {
		display: flex;

		> aside {
			display: block;
			width: 240px;
			margin-left: 1rem;
		}

		h1 {
			text-align: left;
		}

		> main {
			flex: 1;
			margin-right: 1rem;
			padding: 1rem 2rem;

			> nav {
				display: flex;

				> a {
					margin: 0 1rem;

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

  @media (min-width: 992px) {
		> aside {
			width: 320px;
		}
  }
`;
