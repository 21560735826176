import React from 'react';

import {aboutPremiumRoute} from '../../routes';
import PricePackage from '../PricePackage';

export default function PremiumPricePackage() {
	return (
		<PricePackage name="PREMIUM" aboutLinkTo={aboutPremiumRoute()} priceEUR={19} priceCENT={90} color1="#cd5077" color2="#931247">
			<li>Sve iz STANDARD paketa</li>
			<li>Mogućnost postavljanja slike uz svaku stavku cjenika</li>
			<li>Strojna pomoć pri prevođenju</li>
			<li>Mogućnost dodavanja više QR kȏdova za svaki stol ili lokaciju</li>
			<li>Mogućnost vremenskog tempiranja akcija i dnevnih, tjednih i mjesečnih menija</li>
			<li>Mogućnost ubacivanja dodatnog sadržaja: slike, video i dostavni servisi</li>
		</PricePackage>
	);
}
